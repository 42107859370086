/* * {
  margin: 0;
} */
/* .App {
  text-align: center;
} */

.gray-container {
  background-color: #2a3c44;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 1em;
  position: absolute;
  align-items: center;
  justify-content: baseline;
  display: flex;
  flex-direction: column;
  padding-bottom: 3em;
}

.gray-container.detail {
  padding: 3.5em 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin-bottom: 2em;
}

.header-container h1 {
  font-size: 2em;
}

.back-button {
  margin-right: 1em;
  position: relative;
  height: 2em;
  width: 2em;
  background-color: rgba(255, 195, 43, 0.54);
  border-radius: 6px;
}
.back-button h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%) scaleY(1.5);
  font-size: 1.2em;
  color: rgba(255, 255, 190, 0.9);
  font-weight: 300;
}

.pin-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0;
}

.pin-container h1 {
  font-size: 1.8em;
}

.pin-container h2 {
  font-size: 4em;
}

.group-detail-container {
  width: 100%;
}

.group-detail-container h1 {
  font-size: 1.8em;
  margin-bottom: 0.4em;
  margin-top: 1em;
}

.group-detail-container p {
  color: white;
  font-size: 1.5em;
}

.home-amplifi-text {
  font-size: 3em;
  color: #f8d418;
  font-weight: 800;
}

.big-logo {
  max-width: 200px;
  margin-bottom: 2em;
}

.back-button-logo {
  width: 3.8em;
  height: 3.8em;
  margin-bottom: 1em;
}

/* For DJ View */

.card-list {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* gap: 20px 0em; */
  margin: 0em 3em;
}

.card-list > * {
  margin-top: 20px;
}

.card-list h1 {
  font-size: 1.5em;
  width: 100%;
}

.card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card {
  width: 100%;
  background-color: #c3a400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  box-sizing: border-box;
  padding: 9px;
  border-radius: 12px;
  animation-name: card-change;
  animation-duration: 600ms;
}

.card.clickable:active {
  background-color: #a08604;
}

@keyframes card-change {
  from {
    background-color: #ffbb00;
  }
  to {
    background-color: #c3a400;
  }
}

.card-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* flex-grow: 3; */
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 2em 2em;
  border-radius: 12px;
  background-color: #1db954;
  margin-top: 0;
  text-decoration: none;
  border: none;
}

.action-btn:active {
  background-color: #2a2e29;
}

.action-btn p {
  color: white;
  font-size: 1.8em;
  font-weight: 700;
  line-height: 0.7em;
}

.card.tip {
  background-color: #60b452;
}

.song {
  height: 80px;
}

.card-text-wrapper {
  width: 75%;
  /* max-width: 90%; */
  padding-left: 9px;
  max-height: 90%;
}

.card-text-wrapper h1 {
  font-size: 1.1em;
  flex-grow: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  max-height: 1.5em;
  max-width: 100%;
  width: 100%;
  margin: 0;
}

.card-text-wrapper h2 {
  font-size: 0.9em;
  color: #f3e8ac;
  flex-grow: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  max-height: 1.5em;
  max-width: 100%;
  width: 100%;
  margin: 0;
}

.card-text-wrapper.tip h2 {
  color: #b2eca8;
}

.dj-card-right-info {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  width: 23%;
}

.dj-card-right-info h1 {
  font-size: 1.2em;
  text-align: right;
  width: 100%;
}

.dj-card-right-info h2 {
  font-size: 1em;
  color: white;
  font-weight: 400;
  text-align: right;
  width: 100%;
}

/* .dj-card-text-wrapper h3 {
  font-size: 0.9em;
  color: #dcd194;
} */

.share-party-btn {
  flex: 1;
  display: flex;
  margin-left: 1.5em;
  /* width: 3em; */
  height: 3em;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #40df9f 0%, #5640df 0.01%, #803ed5 100%);
  box-shadow: 0px 2px 4px rgba(15, 218, 137, 0.3);
  border-radius: 12px;
}

.share-party-btn:active {
  background-color: #421282;
}

.share-party-btn h3 {
  color: white;
  font-size: 1.1em;
  text-align: center;
}

/* Used for count at the moment */
.card p {
  margin-right: 0.5em;
  color: white;
  font-weight: 700;
}

.card img {
  width: 56px;
  border-radius: 28px;
}

.dj-view-selector {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5em;
  gap: 1.5em;
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: hsl(200, 24%, 25%);
  width: 140px;
  border-radius: 40px;
  padding: 10px;
  height: 50px;
  box-sizing: border-box;
  box-shadow: 0px 1px 14px #19282f;
  color: #96a7af;
  text-decoration: none;
  font-weight: 600;
}

.selected {
  background-color: #ff565e;
  color: white;
}

.tip-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 5em;
}

/* .tip-container p {
  color: rgb(1, 255, 145);
} */

.guest-card-actions {
  display: flex;
  flex-direction: row;
  gap: 0.4em;
  width: 30%;
}

.action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  /* background-color: rgba(188, 176, 114, 0.75); */
}

.action-container .svg-div {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -0.3em;
}

.action-container svg {
  width: 100%;
}

.action-container h1 {
  font-size: 1.2em;
  text-align: center;
  height: 25%;
  margin: -0.4em 0 -0.1em 0;
}

.action-container h2 {
  font-weight: 600;
  color: black;
  text-align: center;
  font-size: 0.7em;
  height: 15%;
  margin-bottom: -0.5em;
}

.card-boost {
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  width: 50px;
  height: 50px;
}

.card-boost p {
  text-align: center;
  margin: 0;
  font-size: 0.7em;
}

.tip-container .tip {
  font-size: 1em;
}

.loading {
  color: white;
  font-size: 1.2em;
}

.upvote {
  width: 0;
  height: 0;
  border-color: orangered;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid orangered;
}
.upvote:after {
  width: 5px;
  height: 10px;
  background-color: orangered;
  content: "";
  position: absolute;
  margin-top: 8px;
  margin-left: -3px;
}

/* form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

label {
  margin: 0.7em 0 0.5em 0.7em;
  color: white;
  font-size: 1.4em;
  font-weight: 500;
}

input[type="text"],
input[type="number"] {
  margin-bottom: 0.2em;
  font-size: 1.3em;
  padding: 0.8em;
  border-radius: 12px;
}

form > span {
  color: rgb(255, 113, 113);
  font-size: 1.1em;
} */

.tips-approval {
  margin: 1em 0;
  background-color: rgb(248, 179, 179);
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 12px;
}

.tips-approval > * {
  color: black;
}

.tips-approval h1 {
  font-size: 1.5em;
}

.tips-approval h2 {
  font-size: 1.2em;
  font-weight: 400;
}

.tips-approval h3 {
  font-size: 1.3em;
  font-weight: 600;
}

.tips-approval button {
  padding: 0.5em 0.2em;
}

.dj-ig-link {
  color: rgb(200, 200, 200);
  font-weight: 600;
  text-decoration: none;
}
